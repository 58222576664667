<template>

  <div class="wrapper">

    <section class="carousel-section">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="3000">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <router-link to="/join">
              <img
                style="object-fit:contain;border-radius: 7px;width: 98%;margin-left: 1%;margin-right: 1%;margin-top: 5px;"
                class="d-block" src="/img/carousel/sliderone.jpeg">
            </router-link>
          </div>
          <div class="carousel-item">
            <router-link to="/deposit">
              <img
                style="object-fit:contain;border-radius: 7px;width: 98%;margin-left: 1%;margin-right: 1%;margin-top: 5px;"
                class="d-block" src="/img/carousel/sliderone.jpeg">
            </router-link>
          </div>
          
          <div class="carousel-item">
            <router-link
              :to="{ name: aviatorCrashGameUrl, params: { IsDemo: 0, providerID: aviatorProviderID, gameName: aviatorGameName, gameID: aviatorGameID } }">
              <img
                style="object-fit:contain;border-radius: 7px;width: 98%;margin-left: 1%;margin-right: 1%;margin-top: 5px;"
                class="d-block" src="/img/carousel/sliderone.jpeg">
            </router-link>
          </div>
          <div class="carousel-item">
            <router-link
              :to="{ name: jetxCrashGameUrl, params: { IsDemo: 0, providerID: jetxProviderID, gameName: jetxGameName, gameID: jetxGameID } }">
              <img
                style="object-fit:contain;border-radius: 7px;width: 98%;margin-left: 1%;margin-right: 1%;margin-top: 5px;"
                class="d-block" src="/img/carousel/sliderone.jpeg">
            </router-link>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </section>


    <div>
      <section class="container">
        <div class="row mt-2 mb-0">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <h5 class="text-dark" style="margin-top: 8px;">
              Top Games
              <!-- <sup style="margin-left: 2px">
                <span class="custom-badge">NEW</span>
              </sup> -->
            </h5>
          </div>
        </div>

        <div class="featured-games-grid justify-content-around">
          <div v-for="(game, index) in aviatorGame" v-bind:key="getKey(index)" style="text-align: center;"
            class="crash-game fly-game">
            <div class="game-icon">
              <img loading="lazy"
                style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;background-color: var(--lightest-gray);border: solid var(--light-gray) 1px;"
                v-bind:src="game.image" alt="Avatar" data-toggle="modal" v-bind:data-target="'#' + game.gameID" />
            </div>

            <div class="modal fade" v-bind:id="game.gameID" tabindex="-1" role="dialog"
              v-bind:aria-labelledby="game.gameID + 'Label'" aria-hidden="true">
              <div class="modal-dialog modal-dialog" role="document">
                <div style="color: var(--darkBackground);" class="modal-content">

                  <div class="modal-header">
                    <h5 class="modal-title" v-bind:id="game.gameID + 'Label'" v-text="game.gameName"></h5>
                    <span type="button" class="close" id="faso-close" data-dismiss="modal">&times;</span>
                  </div>

                  <div class="modal-body">

                    <img loading="lazy"
                      style="border-radius: 5px;border: solid var(--light-gray) 1px; max-width: 500px;"
                      v-bind:src="game.image" alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">

                      <router-link data-dismiss="modal"
                        style="background-color: var(--darkBackground);color: var(--white);" class="btn ww-100 mb-2"
                        :to="{ name: 'aviator', params: { IsDemo: 0, providerID: game.providerID, gameName: game.gameName, gameID: game.gameID } }">
                        Play Now
                      </router-link>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section class="container">
        <div class="row mt-2 mb-0">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <h5 class="text-dark" style="margin-top: 8px;">
              Popular Games
              <!-- <sup style="margin-left: 2px">
                <span class="custom-badge">NEW</span>
              </sup> -->
            </h5>
          </div>
        </div>

        <div class="featured-games-grid justify-content-around">

          <div v-for="(game, index) in homePageGame" v-bind:key="getKey(index)" style="text-align: center;"
            class="crash-game fly-game">
            <div class="game-icon">
              <img loading="lazy"
                style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;background-color: var(--lightest-gray);border: solid var(--light-gray) 1px;"
                v-bind:src="game.image" alt="Avatar" data-toggle="modal" v-bind:data-target="'#' + game.gameID" />
            </div>

            <div class="modal fade" v-bind:id="game.gameID" tabindex="-1" role="dialog"
              v-bind:aria-labelledby="game.gameID + 'Label'" aria-hidden="true">
              <div class="modal-dialog modal-dialog" role="document">
                <div style="color: var(--darkBackground);" class="modal-content">

                  <div class="modal-header">
                    <h5 class="modal-title" v-bind:id="game.gameID + 'Label'" v-text="game.gameName"></h5>
                    <span type="button" class="close" id="faso-close" data-dismiss="modal">&times;</span>
                  </div>

                  <div class="modal-body">

                    <img loading="lazy" style="border-radius: 5px;border: solid var(--light-gray) 1px;max-width: 500px;"
                      v-bind:src="game.image" alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">

                      <router-link data-dismiss="modal"
                        style="background-color: var(--darkBackground);color: var(--white);" class="btn ww-100 mb-2"
                        :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: game.providerID, gameName: game.gameName, gameID: game.gameID } }">
                        Play Now
                      </router-link>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div style="text-align: center;padding: 10px;" class="crash-game fly-game more" v-if="false !== false">
            <div class="game-icon">
              <router-link to="/casino"
                style="background-color: #0e0d0d;color: var(--darkBackground);border: solid var(--darkBackground) 1px;border-radius: 9px;"
                class="btn">
                More <img loading="lazy" style="width: 30px;" src="/img/games.png" alt="Avatar" />
              </router-link>
            </div>
          </div>
        </div>
      </section>

      <div style="display: none;">
        <h1>Crash Game Online on Choplife: A Thrilling World of Destruction and Fun</h1>
        <p>Get ready to dive into the world of Crash games online at Choplife, where destruction and
          adrenaline-pumping action await! From plane crashes to ball and car crashes and everything in between, there's
          no shortage of excitement in these fast-paced games. Whether you're looking to blow off steam or test your
          skills in a physics-based environment, these games offer a unique experience that will leave you wanting more.
          So, buckle up and explore the thrilling realm of online crash games!</p>

        <h2>Crash Game Online: Different Types of Games to Explore</h2>
        <p>Our collection of crash earning games is designed to provide an immersive and entertaining experience. These
          combine elements of chance and strategy in the most fruitful manner possible.</p>
        <p>Whether you're a seasoned player or a newcomer to the world of online gaming, Choplife's wide selection of
          crash games ensures to cater to all levels of expertise and taste.</p>
        <p>Here's a short list of some of the most popular crash game real money categories. Take a look and dive into
          the world of excitement. Play your favorite crash game to earn money in no time!</p>

        <h3>Car Crash Game Online</h3>
        <p>These games put you in the driver's seat, challenging you to cause as much damage as possible in various
          scenarios. From high-speed chases to demolition derbies, car crash games test your driving skills and ability
          to destroy a realistic setting. We offer you to try such a crash car game online at Choplife as Taxi Ride.
        </p>

        <h3>Plane Crash Game Online</h3>
        <p>Experience the thrill of aviation disasters with a plane crash game online! These games challenge you to
          control aircraft in various emergencies, testing your decision-making abilities. Choplife has a whole list
          of popular plane crash money games with excellent graphics and exciting gameplay. Here you can also try online
          crash game gambling. Pick the best online crash game at Choplife and win plane crash game money:</p>
        <ul>
          <li>JetX</li>
          <li>JetX3</li>
          <li>Aviatrix</li>
          <li>Spaceman</li>
          <li>Aviator</li>
          <li>Cometcrash</li>
          <li>Rocketman</li>
          <li>Bigbass</li>
          <li>Cappadocia</li>
          <li>PlinkoX</li>
          <li>SpinX</li>
        </ul>

        <h3>Rocket Crash Game Online</h3>
        <p>Blast off into space with a rocket crash game online and crash online game gambling! With Choplife, you
          can play multiple rocket crash games for real money. This kind of crash online game puts you in control of a
          rocket or spaceship, tasking you to navigate dangerous environments and avoid crashes. Check out these rocket
          crash games at Choplife:</p>
        <ul>
          <li>Rocketman</li>
          <li>Aviator</li>
        </ul>

        <h2>Crash Game Online: Playing for Fun vs. Earning Money</h2>
        <p>While many people enjoy playing crash games online for fun in demo mode at Choplife, there's also the
          opportunity to earn money through online gambling. By participating in crash game online gambling, players can
          potentially win real cash by betting on the outcome of various games.</p>

        <h3>Crash Game Online Earn Money: How It Works</h3>
        <p>In crash game online gambling, players place bets on the outcome of a specific game or event. This can
          involve betting on which car will cause the most destruction in a car crash game or predicting the outcome of
          a plane crash in a plane crash game online earn money scenario. The game's algorithm determines the odds of
          winning, and if a player's prediction is correct, they'll win a payout based on those odds.</p>
        <p>Although there is no magic formula to win a crash game, with practice and a bit of luck, you can certainly
          make crash game money while having a good time.</p>

        <h3>Crash Game Online Gambling: Finding the Right Casino</h3>
        <p>Players will need to find a crash game online casino to participate in crash game online gambling.
          Choplife is a reputable site with a wide selection of crash game online titles. So look no further and
          start playing!</p>
        <ul>
          <li><strong>Game Selection:</strong> With our wide selection of games, you can find a game that suits your
            interests and preferences. Some of the crash skill games might require a bit of practice; hence, it is
            important to select games based on your playing style and ability.</li>
          <li><strong>Security and Reputation:</strong> Choplife online casino has a solid reputation and uses
            state-of-the-art security measures to protect your personal information and MPESA transactions.</li>
          <li><strong>Bonuses and Promotions:</strong> Choplife offers sign-up bonuses, deposit bonuses, and ongoing
            promotions for online crash-game gambling.</li>
          <li><strong>Customer Support:</strong> Excellent Choplife customer support is available 24/7 on 0716797777
            for any issues or questions.</li>
        </ul>

        <h1>Crash Game Online: Tips and Strategies for Success</h1>
        <p>Whether you're playing for fun or gambling with real money, it's essential to have a solid strategy when
          diving into the world of crash games online. Here are some tips to help you succeed:</p>

        <ol>
          <li><strong>Know the Game:</strong> Familiarize yourself with the rules, controls, and mechanics of the crash
            game online you're playing. This will help you make more informed decisions and improve your overall
            performance.</li>
          <li><strong>Practice, Practice, Practice:</strong> The more you play, the better you'll become. Spend time
            honing your skills in each crash game online to improve your chances of success.</li>
          <li><strong>Manage Your Bankroll:</strong> When gambling on crash games online, it's essential to have a
            budget and stick to it. This will help prevent you from losing more money than you can afford. Playing crash
            money games online does involve real money bets. Thanks to the quick nature of the crash betting game, it is
            easy to forget about your budget and spend more than you originally intended to. Hence, it is crucial to
            have a preset budget before you start your crash game betting session.</li>
          <li><strong>Know When to Quit:</strong> It's important to recognize when to walk away from a crash game
            online, whether you're ahead or behind. Knowing when to quit can help you maintain a healthy balance between
            fun and responsible gambling.</li>
        </ol>

        <h2>Experience the Excitement of Crash Game Online Today!</h2>
        <p>Choplife offers the world of crash games online with endless fun and excitement for players of all
          interests and skill levels. You can find multiple crash game online real money titles at Choplife. With
          various game types, the opportunity to earn real money through gambling, and countless strategies for success,
          there's never been a better time to dive into this thrilling world of destruction and mayhem. So, what are you
          waiting for? Start exploring the fantastic universe of crash games online today!</p>

        <h2>FAQ</h2>
        <dl>
          <dt>What is a crash money game?</dt>
          <dd>Crash gaming has become popular in recent years, providing a thrilling and fast-paced way to bet online.
            The game includes betting on the outcome of a simulated "crash," in which the multiplier builds until it
            abruptly crashes, resulting in the loss of all bets.</dd>

          <dt>How to play the Rocket crash game real money?</dt>
          <dd>To play the aviator crash game real money, you can create an account with Choplife, load your account
            with a minimum deposit, find the crash game you prefer, and start making your bets.</dd>
        </dl>
      </div>

    </div>

    <BottomNavigation></BottomNavigation>

  </div>

</template>

<script>
const BottomNavigation = () => import('./BottomNavigation.vue')
import casino from "@/services/casino";

export default {
  name: 'Home',
  data() {
    return {
      aviatorProviderID: 7,
      aviatorGameID: "aviator",
      aviatorGameName: "Aviator",
      aviatorCrashGameUrl: "aviator",
      //
      jetxProviderID: 5,
      jetxGameID: "JetX",
      jetxGameName: "Jet X",
      jetxCrashGameUrl: "jetx",
      //
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      outcome: {},
      msisdn: "",
      password: "",
      code: "",
      action_verify_password: false,
      action_success: false,
      message: "",
      to_search: '',
      searchgamesdata: [],
      allgames: [],
      categories: [],
      casinocategories: [9, 4, 7, 12, 18, 17, 16],
      casinocategoriesObj: [
        {
          "id": 9,
          "title": "Popular"
        },
        {
          "id": 4,
          "title": "Slots"
        },
        {
          "id": 17,
          "title": "Classic Casino"
        },
        {
          "id": 8,
          "title": "Table Games"
        }
      ],
      casinoCategory: 9,
      casinoCategoryActive: {
        "id": 1,
        "name": "Crash"
      },
      casinoCategoryIndex: 0,
      disable: true,
      loading: false,
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0,
      currentPage: 1,
      perPage: 100,
      aviatorGame: [
        {
          providerID: 7,
          gameID: "aviator",
          gameName: "Aviator",
          image: "/img/home/Aviator.jpg",
        },
        {
          providerID: 5,
          gameID: "JetX",
          gameName: "Jet X",
          image: "/img/home/JetX.jpg",
        },
        {
          providerID: 3,
          gameID: "1",
          gameName: "Comet",
          image: "/img/home/CometCrash.jpg",
        },
      ],
      homePageGame: [
        {
          providerID: 3,
          gameID: "4",
          gameName: "Taxi Ride",
          image: "/img/home/Taxi.png",
        },
        {
          providerID: 3,
          gameID: "5",
          gameName: "Monkey Bizniz",
          image: "/img/home/Monkey.png",
        },
        {
          providerID: 4,
          gameID: "rocketman",
          gameName: "Rocket Man",
          image: "/img/home/Rocketman.jpg",
        },
        {
          providerID: 5,
          gameID: "JetX3",
          gameName: "JetX3",
          image: "/img/home/JetX3.png",
        },
        {
          providerID: 5,
          gameID: "PlinkoX",
          gameName: "PlinkoX",
          image: "/img/home/Plinko.jpg",
        },
        {
          providerID: 5,
          gameID: "MineIsland",
          gameName: "MineIsland",
          image: "/img/home/MineIsland.png",
        },
        {
          providerID: 3,
          gameID: "3",
          gameName: "Paperplane",
          image: "/img/home/Mandege.png",
        },
        // {
        //   providerID: 1,
        //   gameID: "vswaysbbb",
        //   gameName: "Big Bass Bonanza Megaways",
        //   image: "/img/home/Bass.png",
        // },
        // {
        //   providerID: 3,
        //   gameID: "10",
        //   gameName: "Dare Devil",
        //   image: "/img/home/Daredevil.png",
        // },
        {
          providerID: 3,
          gameID: "14",
          gameName: "Rugby Run",
          image: "/img/home/Rugby.png",
        },
        // {
        //   providerID: 9,
        //   gameID: "cointoss",
        //   gameName: "Coin Toss",
        //   image: "/img/home/cointoss.png",
        // },
        // {
        //   providerID: 1,
        //   gameID: "vs5aztecgems",
        //   gameName: "Aztec Gems",
        //   image: "/img/home/Aztec.jpg",
        // },
        // {
        //   providerID: 9,
        //   gameID: "reelwheel",
        //   gameName: "Spin to Win",
        //   image: "/img/home/Spin2Win.jpg",
        // }
      ],

    }
  },
  components: {
    BottomNavigation
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "home");
    this.reloadProfile();

    var categories = [];

    var vm = this;

    this.jQuery.each(vm.games, function (k, v) {
      var key = v.Category.replaceAll(/ /g, "");
      var exists = false;

      // lets check if category exists
      vm.jQuery.each(categories, function (i, x) {

        if (x.key == key) {

          x.games.push(v);
          exists = true;
        }

        categories[i] = x;
      });

      if (!exists) {

        var gm = [];
        gm.push(v);

        var cat = {
          name: v.Category,
          key: key,
          games: gm,
        };

        categories.push(cat);
      }
    });

    this.groupedGames = categories;

    this.getCategories();

    this.showSlides();

  },

  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },

  methods: {
    handleGesture: function () {

      var scrollLeft = document.getElementById('catsec').scrollLeft

      if (this.touchendX < this.touchstartX && (this.touchstartX - this.touchendX) > 125) {
        console.log('Swiped Left');
        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        let count = this.casinocategoriesObj.length;
        let newIndex = 0
        if (index < 0) {
          newIndex = 1;
        } else {
          newIndex = index + 1;
        }
        console.log("Old index: " + index)
        if (newIndex <= count) {
          this.casinoCategoryActive = this.casinocategoriesObj[newIndex];
          document.getElementById('catsec').scrollLeft = scrollLeft + 50;
        }
      }

      if (this.touchendX > this.touchstartX && (this.touchendX - this.touchstartX) > 125) {
        console.log('Swiped Right');

        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        // let count = this.casinocategoriesObj.length;
        if ((index - 1) > -1) {
          this.casinoCategoryActive = this.casinocategoriesObj[(index - 1)];
          document.getElementById('catsec').scrollLeft = scrollLeft - 50;
        }

      }

      if (this.touchendY < this.touchstartY) {
        console.log('Swiped Up');
      }

      if (this.touchendY > this.touchstartY) {
        console.log('Swiped Down');
      }

      if (this.touchendY === this.touchstartY) {
        console.log('Tap');
      }
    },
    isActive: function (item) {
      // console.log(item)
      if (item.id == this.casinoCategoryActive.id) {
        return ' active';
      } else {
        return '';
      }
    },


    performSearch: function () {

      var keyword = this.to_search;
      var games = this.allgames;

      console.log(keyword);

      var results = games.filter(function (el) {

        console.log(el.game_name);
        var game_name = el.game_name.toLowerCase();

        return game_name.match(keyword.toLowerCase()) == null ? false : game_name.match(keyword.toLowerCase()).length > 0;

      });

      console.log('search results ' + JSON.stringify(this.searchgamesdata, undefined, 2))

      if (results.length > 0 && keyword !== "") {

        this.searchgamesdata = results;

      } else {

        this.searchgamesdata = [];

      }

    },

    keyUpSearch: function () {

      if (this.to_search == '') {

        this.searchgamesdata = [];

      }

    },

    loadPage: function (launch_url) {

      window.open(launch_url, '_blank');

    },

    getImg: function (img) {

      return img.replaceAll("/rec/325/", "/square/200/")

    },

    showSlides: function () {

      var vm = this;

      var slideIndex = 0;

      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {

        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {

        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {

        dots[i].className = dots[i].className.replace(" active", "");

      }

      if (slides[slideIndex - 1]) {

        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";

      }

      setTimeout(function () {

        vm.showSlides();

      }, 2000)  // Change image every 2 seconds

    },

    getLaunchURL: function (launch_url) {

      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;

    },

    getKey: function (index) {

      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },

    generateGameUrl(game) {
      return `/casinogames?IsDemo=0&providerID=${game.provider_id}&gameName=${game.game_name}&gameID=${game.game_id}&launchURL=${game.launch_url}&launchType=${game.launch_type}&launchParameters=${game.launch_parameters}`;
    },

    closeModal() {
      const modals = document.getElementsByClassName('modal-window');
      for (let i = 0; i < modals.length; i++) {
        if (!modals[i].classList.contains('d-none')) {
          modals[i].classList.add('d-none');
          break;
        }
      }
    },

    setSportMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id)
      this.$router.push({ name: 'sport', params: {} });
    },

    getCategories: function () {

      var vm = this;

      var path = "/category";

      casino.get(path)
        .then(res => {

          vm.categories = res.data;

        })
        .catch(err => {

          if (err.response) {
            this.$toast.open({
              message: err.response.data.error_message,
              type: 'error',
              position: 'top'
            });

          } else if (err.request) {

            console.log(JSON.stringify(err.request));

          } else {

            console.log(JSON.stringify(err));

          }
        })
    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>

<style scoped>
.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);

}

.list-active {
  background-color: #F08B05;
}

.row {
  row-gap: .5em;
}

.crash-game {
  width: 100%;
}

.crash-game.more {
  grid-column: 1/span 5;
}


.fly-game {
  text-align: center;
}

.app {
  height: 100% !important;
}

.rocket-fastball {
  height: 100%;
}

h5 {
  font-size: 1rem;
}

.rocket-fastball {
  height: 100%;
  width: auto;
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window>div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}

.modal-window header {
  font-weight: bold;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}

.modal-close:hover {
  color: black;
}

.featured-games-grid {
  display: grid;
  gap: 0.3rem;
  grid-template-columns: repeat(3, 1fr);
}
</style>